import Vue from 'vue';
import App from './App.vue';
import 'regenerator-runtime/runtime';
import './filters';
import './plugins/bootstrap-vue';
import './plugins/axios';
import KeycloakPlugin from './plugins/keycloak';
import {initialize} from './plugins/i18n';
import {initAppData} from '@/utilities/app';

import store from './store';
import router from './router';

// import site-wide styles
import '@/assets/css/main.scss';

// import fontawesome support
import {library} from '@fortawesome/fontawesome-svg-core';
import {faVenus} from '@fortawesome/free-solid-svg-icons/faVenus';
import {faMars} from '@fortawesome/free-solid-svg-icons/faMars';
import {faGenderless} from '@fortawesome/free-solid-svg-icons/faGenderless';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import CustomRouterLink from '@/components/CustomRouterLink';

library.add(faMars, faVenus, faGenderless);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('custom-router-link', CustomRouterLink);

// import dayjs
import * as dayjs from 'dayjs';
dayjs.extend(require('dayjs/plugin/utc'));

// import Vuelidate support
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// import v-calendar
import VCalendar from 'v-calendar';
Vue.use(VCalendar);

// custom toast plugin
import Toast from '@/utilities/toasts';
Vue.use(Toast);

// Methods that can be used throughout different parts of the app.
import generalMixin from './mixins/general';
Vue.mixin(generalMixin);

Vue.config.productionTip = false;

import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas);

const kcConfig = {
    url: process.env.VUE_APP_LOCAL_KEYCLOAK_URL
        ? process.env.VUE_APP_LOCAL_KEYCLOAK_URL
        : process.env.NODE_ENV === 'development'
        ? 'https://auth.staarsurgical.artlogicdev.net/auth'
        : process.env.VUE_APP_KEYCLOAK_URL,
    realm: 'orion',
    clientId: 'vue-local-auth',
};

console.log(`NODE_ENV: ${process.env.NODE_ENV}`);

const kcInit = {
    onLoad: 'login-required',
    //redirectUri: location.origin,
};

console.log('Keycloak URL:', kcConfig.url);
// Create keycloak instance and make it available on our Vue instance
Vue.use(KeycloakPlugin, kcConfig);

Vue.$keycloak
    .init(kcInit)
    .then(async (auth) => {
        if (!auth) return;

        try {
            // User is authenticated!
            let tokenString = Vue.$keycloak.tokenParsed;

            /* If the token includes the 'log-view' role, set the permission for the user to view the UAC log. */
            await store.dispatch(
                'logging/setLogViewPermission',
                JSON.stringify(tokenString.resource_access).includes('log-view')
            );

            // Initialize app data
            await initAppData();
        } finally {
            // Start the app
            const locale = store.getters['user/currentUser']?.language;
            initialize(Vue, store, {locale}).then(async () => {
                window.$app = new Vue({
                    router,
                    store,
                    render: (h) => h(App),
                }).$mount('#app');

                store.$app = window.$app;
            });
        }
    })
    .catch((err) => console.log(err));
