import axios from 'axios';
//
// STATE
//
const state = () => ({
    featureFlags: [],
});
//
// GETTERS
//
const getters = {
    getFeatureFlagByName(state) {
        return (name) => state.featureFlags.find((flag) => flag.featureFlagName === name) || {};
    },
};
//
// MUTATIONS
//
const mutations = {
    setFeatureFlags(state, value) {
        state.featureFlags = value;
    },
};
//
// ACTIONS
//
const actions = {
    async fetchFeatureFlags({commit}) {
        const response = await this._vm.$http.get(`/featureflag/get-all-feature-flags`);

        if (response.status == 200) {
            commit('setFeatureFlags', response.data);
        } else {
            console.log('error fetching Feature Flags');
            console.log(response.status);
        }
    },
};

//
// DEFAULT EXPORT
//
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
