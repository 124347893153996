<template>
    <b-modal
        id="surgeon-customer-selector"
        centered
        no-fade
        hide-backdrop
        no-close-on-backdrop
        no-close-on-esc
        content-class="surgeon-customer-selector-content"
        header-class="surgeon-customer-selector-header"
        body-class="surgeon-customer-selector-body"
        footer-class="surgeon-customer-selector-footer"
        visible
        @cancel="onCancel"
    >
        <template #modal-header>
            <div>
                {{ headerText }}
            </div>
        </template>
        <template #default>
            <b-container>
                <!-- Selected Surgeon -->
                <b-row
                    v-if="
                        modalState.name === ModalStateNames.SURGEON_SELECTED ||
                        modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED
                    "
                    class="mb-3"
                >
                    <b-col>
                        <span>
                            <span class="text-gray-darker heavy">
                                {{ selectedSurgeon.lastName }}, {{ selectedSurgeon.firstName }}
                            </span>
                            <span class="text-small text-gray-dark">
                                <em>{{ selectedSurgeon.contactPartyNumber }}</em>
                            </span>
                        </span>
                    </b-col>
                </b-row>
                <!-- Selected Customer -->
                <b-row
                    v-if="
                        modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED ||
                        modalState.name === ModalStateNames.CUSTOMER_SELECTED
                    "
                >
                    <b-col>
                        <span>
                            <span class="text-gray-darker heavy">
                                {{ selectedCustomer.name }}
                            </span>
                            <span class="text-gray-darker">
                                {{ selectedCustomer.city }}, {{ selectedCustomer.country }}
                            </span>
                            <span> {{ selectedCustomer.city }}, {{ selectedCustomer.state }} </span>
                            <span class="text-small text-gray-dark">
                                <em>{{ selectedCustomer.ocosCustomerNumber }}</em>
                            </span>
                        </span>
                    </b-col>
                </b-row>
                <!-- Search Bar -->
                <b-row
                    v-if="
                        modalState.name === ModalStateNames.BASE ||
                        modalState.name === ModalStateNames.VIEW_ALL_SURGEONS ||
                        modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS ||
                        modalState.name === ModalStateNames.SURGEON_SELECTED
                    "
                >
                    <b-col>
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input
                                trim
                                ref="searchInput"
                                type="search"
                                v-model="searchText"
                                :placeholder="searchPlaceholderText"
                            >
                            </b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-card class="mt-2">
                    <div v-if="showSpinner">
                        <SpinnerOverlay position="absolute"></SpinnerOverlay>
                    </div>
                    <!-- Surgeons List -->
                    <SurgeonCustomerSelectorSurgeonsList
                        :modalReady="modalReady"
                        :ModalStateNames="ModalStateNames"
                        :modalState="modalState"
                        :searchText="searchText"
                        :surgeonsProcessed="surgeonsProcessed"
                        @scroll="onScrollSurgeons"
                        @view-all-surgeons="onViewAllSurgeons"
                        @surgeon-selected-from-list="onSurgeonSelected"
                    />
                    <!-- Customers List -->
                    <SurgeonCustomerSelectorCustomersList
                        :modalReady="modalReady"
                        :ModalStateNames="ModalStateNames"
                        :modalState="modalState"
                        :searchText="searchText"
                        :customersProcessed="customersProcessed"
                        @scroll="onScrollCustomers"
                        @view-all-customers="onViewAllCustomers"
                        @customer-selected-from-list="onCustomerSelected"
                    />
                </b-card>
            </b-container>
        </template>
        <template #modal-footer>
            <b-container>
                <!-- Default Selection -->
                <b-row
                    v-if="
                        modalState.name !== ModalStateNames.VIEW_ALL_SURGEONS &&
                        modalState.name !== ModalStateNames.VIEW_ALL_CUSTOMERS
                    "
                >
                    <b-col>
                        <b-form-checkbox
                            :disabled="!enableDefaultSelectionCheckbox"
                            v-model="defaultSelectionSetting"
                            :value="DefaultSelectionOptions.SET_DEFAULT"
                            :unchecked-value="DefaultSelectionOptions.UNSET_DEFAULT"
                        >
                            {{ t('surgeonCustomerSelector_MakeMyDefault') }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <!-- View Dashboard -->
                <b-row
                    v-if="
                        modalState.name !== ModalStateNames.VIEW_ALL_SURGEONS &&
                        modalState.name !== ModalStateNames.VIEW_ALL_CUSTOMERS
                    "
                    class="mt-3"
                >
                    <b-col>
                        <b-button
                            block
                            variant="primary"
                            :disabled="!enableViewDashboardButton"
                            @click="onViewDashboard"
                        >
                            {{ t('surgeonCustomerSelector_ViewDashboard') }}
                        </b-button>
                    </b-col>
                </b-row>
                <!-- Cancel or Back -->
                <b-row class="mt-2 mb-3">
                    <b-col class="text-center">
                        <b-button
                            v-if="modalState.name === ModalStateNames.BASE"
                            variant="outline-none"
                            class="text-gray-dark"
                            @click="onCancel"
                        >
                            {{ t('cancel') }}
                        </b-button>
                        <b-button
                            v-else
                            variant="outline-none"
                            class="text-gray-dark"
                            @click="onBack"
                        >
                            {{ t('inventory_Back') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </template>
    </b-modal>
</template>

<script>
import axios from 'axios';
import {debounce} from 'lodash';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {setSearchResultProperties} from '@/utilities/search';
import SpinnerOverlay from '@/views/layout/SpinnerOverlay.vue';
import SurgeonCustomerSelectorSurgeonsList from '@/components/SurgeonCustomerSelectorSurgeonsList.vue';
import SurgeonCustomerSelectorCustomersList from '@/components/SurgeonCustomerSelectorCustomersList.vue';

const DefaultQueryParams = () => ({
    customers: {
        page: 1,
        per_page: 100,
        offset: 0,
        query: '',
        doctorId: null,
    },
    surgeons: {
        page: 1,
        per_page: 100,
        offset: 0,
        query: '',
    },
});
/**
 * The states of the modal
 */
const ModalStateNames = Object.freeze({
    BASE: 'BASE',
    CUSTOMER_SELECTED: 'CUSTOMER_SELECTED',
    SURGEON_SELECTED: 'SURGEON_SELECTED',
    CUSTOMER_AND_SURGEON_SELECTED: 'CUSTOMER_AND_SURGEON_SELECTED',
    VIEW_ALL_SURGEONS: 'VIEW_ALL_SURGEONS',
    VIEW_ALL_CUSTOMERS: 'VIEW_ALL_CUSTOMERS',
});

/**
 * The options for setting the default selection
 */
const DefaultSelectionOptions = Object.freeze({
    SET_DEFAULT: 'SET_DEFAULT',
    UNSET_DEFAULT: 'UNSET_DEFAULT',
});

/**
 * The debounce delay for the search input (in milliseconds)
 */
const SEARCH_INPUT_DEBOUNCE_DELAY = 500;

/**
 * The spinner delay for when the modal is not ready (in milliseconds)
 */
const SPINNER_DELAY = 250;

/**
 * The scroll offset to request next page
 */
const SCROLL_OFFSET = 100;

/**
 * Allows the user to select a customer with or without a surgeon.
 * The selection can be saved as the default.
 *
 * @emits cancel Emitted when the user cancels the selection
 * @emits customer-selected Emitted when the user selects a customer.
 *   At this time, a surgeon may or may not be selected. Both the
 *   selected customer and surgeon (if any) are sent with the event.
 */
export default {
    name: 'SurgeonCustomerSelectorModal',
    components: {
        SpinnerOverlay,
        SurgeonCustomerSelectorSurgeonsList,
        SurgeonCustomerSelectorCustomersList,
    },
    data() {
        return {
            ModalStateNames,
            DefaultSelectionOptions,
            modalReady: false,
            modalStateHistory: [], // [Object {name, searchText}]
            searchText: '',
            fetchOnSearchTextChange: true,
            customers: [],
            surgeons: [],
            customersProcessed: [],
            surgeonsProcessed: [],
            selectedCustomer: null,
            selectedSurgeon: null,
            defaultSelectionSetting: DefaultSelectionOptions.UNSET_DEFAULT,
            customersQueryParams: DefaultQueryParams().customers,
            surgeonsQueryParams: DefaultQueryParams().surgeons,
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters('user', ['defaultCustomerId', 'defaultDoctorId']),
        ...mapGetters({
            customersRequestToken: 'user/userCustomers/requestToken',
            customersFetched: 'user/userCustomers/list',
            customerFetchDetails: 'user/userCustomers/details',
            surgeonsRequestToken: 'user/userDoctors/requestToken',
            surgeonsFetched: 'user/userDoctors/list',
            surgeonFetchDetails: 'user/userDoctors/details',
        }),
        modalState() {
            // There should always be at least one state in the history
            return this.modalStateHistory[0];
        },
        headerText() {
            let headerText = '';
            if (this.modalState.name === ModalStateNames.VIEW_ALL_SURGEONS) {
                headerText = this.t('surgeonCustomerSelector_SelectSurgeon');
            } else if (this.modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS) {
                headerText = this.t('surgeonCustomerSelector_SelectCustomer');
            } else if (
                this.modalState.name === ModalStateNames.CUSTOMER_SELECTED ||
                this.modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED
            ) {
                headerText = this.t('surgeonCustomerSelector_Selected');
            } else {
                headerText = this.t('select');
            }
            return headerText;
        },
        searchPlaceholderText() {
            let placeholderText = '';
            if (this.modalState.name === ModalStateNames.BASE) {
                placeholderText = this.t('surgeonCustomerSelector_placeholderText_1');
            } else if (this.modalState.name === ModalStateNames.SURGEON_SELECTED) {
                placeholderText = this.t('surgeonCustomerSelector_placeholderText_2');
            } else if (this.modalState.name === ModalStateNames.VIEW_ALL_SURGEONS) {
                placeholderText = this.t('surgeonCustomerSelector_placeholderText_3');
            } else if (this.modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS) {
                placeholderText = this.t('surgeonCustomerSelector_placeholderText_4');
            }
            return placeholderText;
        },
        enableDefaultSelectionCheckbox() {
            let enable = false;
            if (
                (this.modalState.name === ModalStateNames.CUSTOMER_SELECTED &&
                    this.selectedCustomer) ||
                (this.modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED &&
                    this.selectedSurgeon &&
                    this.selectedCustomer)
            ) {
                enable = true;
            }
            return enable;
        },
        enableViewDashboardButton() {
            let enable = false;
            if (
                (this.modalState.name === ModalStateNames.CUSTOMER_SELECTED &&
                    this.selectedCustomer) ||
                (this.modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED &&
                    this.selectedSurgeon &&
                    this.selectedCustomer)
            ) {
                enable = true;
            }
            return enable;
        },
    },
    watch: {
        searchText(value) {
            if (this.fetchOnSearchTextChange) {
                this.onDebouncedSearchTextChange(value);
            }
            this.fetchOnSearchTextChange = true;
        },
        async modalState(state) {
            switch (state.name) {
                case ModalStateNames.BASE:
                    this.searchText = state.searchText || '';
                    this.clearSelectedSurgeon();
                    this.clearSelectedCustomer();
                    this.clearSurgeons();
                    this.clearCustomers();
                    this.clearDefaultSelectionSetting();
                    this.resetQueryParams();
                    this.customersQueryParams.query = this.searchText;
                    this.surgeonsQueryParams.query = this.searchText;
                    await this.fetchSearchResults({includeCustomers: true, includeSurgeons: true});
                    break;
                case ModalStateNames.SURGEON_SELECTED:
                    this.fetchOnSearchTextChange = false;
                    this.searchText = '';
                    break;
                case ModalStateNames.CUSTOMER_SELECTED:
                    this.clearSelectedSurgeon();
                    this.getDefaultSelectionSetting();
                    break;
                case ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED:
                    this.getDefaultSelectionSetting();
                    break;
                case ModalStateNames.VIEW_ALL_SURGEONS:
                case ModalStateNames.VIEW_ALL_CUSTOMERS:
                    // Don't fetch any data here. These states are just for viewing
                    // more of the data already fetched.
                    break;
                default:
                    break;
            }
            if (this.$refs.searchInput) {
                // Move cursor to the end of the search text
                this.$refs.searchInput.$el.setSelectionRange(
                    this.$refs.searchInput.$el.value.length,
                    this.$refs.searchInput.$el.value.length
                );
                this.$refs.searchInput.$el.focus();
            }
        },
    },
    mounted() {
        this.modalStateHistory.unshift({
            name: ModalStateNames.BASE,
            searchText: '',
        });
        this.getDefaultSelectionSetting();
    },
    methods: {
        ...mapActions('user', [
            'setActiveCustomerId',
            'setActiveDoctorId',
            'setDefaultCustomerId',
            'setDefaultDoctorId',
        ]),
        ...mapMutations({
            setRequestTokenCustomers: 'user/userCustomers/setRequestToken',
            setRequestTokenSurgeons: 'user/userDoctors/setRequestToken',
        }),
        ...mapActions({
            fetchMyCustomers: 'user/userCustomers/fetchList',
            fetchMySurgeons: 'user/userDoctors/fetchList',
        }),
        /**
         * Show the spinner after a delay if the modal is still not ready.
         */
        showSpinnerWithDelay() {
            setTimeout(() => {
                this.showSpinner = !this.modalReady;
            }, SPINNER_DELAY);
        },
        /**
         * Go to the given modal state
         *
         * @param {String} stateName
         */
        goToModalState(stateName) {
            this.modalStateHistory.unshift({
                name: stateName,
            });
        },
        /**
         * Fetch customers and/or doctors(surgeons) according to the given
         * parameters. After the request is complete, signal that the modal
         * is ready for use.
         *
         * @param {Object} params
         */
        async fetchSearchResults(params) {
            const {includeCustomers, includeSurgeons} = params;
            this.modalReady = false;
            this.showSpinnerWithDelay();

            try {
                if (includeCustomers && includeSurgeons) {
                    this.customersRequestToken && this.customersRequestToken.cancel();
                    this.setRequestTokenCustomers(axios.CancelToken.source());
                    this.surgeonsRequestToken && this.surgeonsRequestToken.cancel();
                    this.setRequestTokenSurgeons(axios.CancelToken.source());
                    await Promise.all([
                        this.fetchMyCustomers(this.customersQueryParams),
                        this.fetchMySurgeons(this.surgeonsQueryParams),
                    ]);
                    this.surgeons = this.surgeons.concat(this.surgeonsFetched);
                    this.customers = this.customers.concat(this.customersFetched);
                    this.processSurgeons(this.surgeonsQueryParams.query, this.surgeons);
                    this.processCustomers(this.customersQueryParams.query, this.customers);
                } else if (includeCustomers) {
                    this.customersRequestToken && this.customersRequestToken.cancel();
                    this.setRequestTokenCustomers(axios.CancelToken.source());
                    await this.fetchMyCustomers(this.customersQueryParams);
                    this.customers = this.customers.concat(this.customersFetched);
                    this.processCustomers(this.customersQueryParams.query, this.customers);
                } else if (includeSurgeons) {
                    this.surgeonsRequestToken && this.surgeonsRequestToken.cancel();
                    this.setRequestTokenSurgeons(axios.CancelToken.source());
                    await this.fetchMySurgeons(this.surgeonsQueryParams);
                    this.surgeons = this.surgeons.concat(this.surgeonsFetched);
                    this.processSurgeons(this.surgeonsQueryParams.query, this.surgeons);
                }
                this.modalReady = true;
                this.showSpinner = !this.modalReady;
            } catch (error) {
                if (error.toString() !== 'Cancel') {
                    throw error;
                }
            }
        },
        /**
         * Handle the event when the customers list is scrolled
         *
         * @param {Object} event
         */
        async onScrollCustomers(event) {
            // If the end of the list has been reached
            if (
                event.target.scrollTop + event.target.clientHeight >=
                event.target.scrollHeight - SCROLL_OFFSET
            ) {
                if (this.modalReady) {
                    await this.getNextCustomers();
                }
            }
        },
        /**
         * Handle the event when the surgeons list is scrolled
         *
         * @param {Object} event
         */
        async onScrollSurgeons(event) {
            // If the end of the list has been reached
            if (
                event.target.scrollTop + event.target.clientHeight >=
                event.target.scrollHeight - SCROLL_OFFSET
            ) {
                if (this.modalReady) {
                    await this.getNextSurgeons();
                }
            }
        },
        /**
         * Fetches the next page of customers if there are any left
         */
        async getNextCustomers() {
            if (this.customersProcessed.length < this.customerFetchDetails.total) {
                this.customersQueryParams.page++;
                await this.fetchSearchResults({includeCustomers: true, includeSurgeons: false});
            }
        },
        /**
         * Fetches the next page of surgeons if there are any left
         */
        async getNextSurgeons() {
            if (this.surgeonsProcessed.length < this.surgeonFetchDetails.total) {
                this.surgeonsQueryParams.page++;
                await this.fetchSearchResults({includeCustomers: false, includeSurgeons: true});
            }
        },
        /**
         * Handle the debounced search text change
         *
         * @param {String} searchText
         */
        onDebouncedSearchTextChange: debounce(async function (searchText) {
            this.modalState.searchText = searchText;
            if (this.modalState.name === ModalStateNames.BASE) {
                this.clearCustomers();
                this.clearSurgeons();
                this.resetQueryParams();
                this.customersQueryParams.query = searchText;
                this.customersQueryParams.doctorId = this.selectedSurgeon?.doctorId;
                this.surgeonsQueryParams.query = searchText;
                await this.fetchSearchResults({includeCustomers: true, includeSurgeons: true});
            } else if (this.modalState.name === ModalStateNames.VIEW_ALL_SURGEONS) {
                this.clearSurgeons();
                this.surgeonsQueryParams = DefaultQueryParams().surgeons;
                this.surgeonsQueryParams.query = searchText;
                await this.fetchSearchResults({includeCustomers: false, includeSurgeons: true});
            } else if (this.modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS) {
                this.clearCustomers();
                this.customersQueryParams = DefaultQueryParams().customers;
                this.customersQueryParams.query = searchText;
                await this.fetchSearchResults({includeCustomers: true, includeSurgeons: false});
            } else if (this.modalState.name === ModalStateNames.SURGEON_SELECTED) {
                this.clearCustomers();
                this.customersQueryParams = DefaultQueryParams().customers;
                this.customersQueryParams.query = searchText;
                this.customersQueryParams.doctorId = this.selectedSurgeon?.doctorId;
                await this.fetchSearchResults({includeCustomers: true, includeSurgeons: false});
            }
        }, SEARCH_INPUT_DEBOUNCE_DELAY),
        /**
         * Set properties for the found search text and sort the surgeons
         *
         * @param {String} searchText
         */
        processSurgeons(searchText, surgeons) {
            this.surgeonsProcessed = setSearchResultProperties(searchText, surgeons, [
                'firstName',
                'lastName',
                'contactPartyNumber',
            ]);
        },
        /**
         * Set properties for the found search text and sort the customers
         *
         * @param {String} searchText
         */
        processCustomers(searchText, customers) {
            this.customersProcessed = setSearchResultProperties(searchText, customers, [
                'name',
                'ocosCustomerNumber',
            ]);
        },
        /**
         * Scroll both the customer and surgeon lists to the top
         */
        scrollListsToTop() {
            if (this.$refs.surgeonsListContents) {
                this.$refs.surgeonsListContents.scrollTop = 0;
            }
            if (this.$refs.customersListContents) {
                this.$refs.customersListContents.scrollTop = 0;
            }
        },
        /**
         * Handle navigation to the View All Surgeons state
         */
        onViewAllSurgeons() {
            this.goToModalState(ModalStateNames.VIEW_ALL_SURGEONS);
        },
        /**
         * Handle navigation to the View All Customers state
         */
        onViewAllCustomers() {
            this.goToModalState(ModalStateNames.VIEW_ALL_CUSTOMERS);
        },
        /**
         * Handle a surgeon selection by updating the customers list and
         * automatically selecting the customer if there is only one. If
         * there is more than one customer, allow the user to select one.
         *
         * @param {Object} surgeon
         */
        async onSurgeonSelected(surgeon) {
            this.selectedSurgeon = surgeon;

            this.clearSelectedCustomer();
            this.clearCustomers();
            this.clearDefaultSelectionSetting();

            this.resetQueryParams();
            this.customersQueryParams.query = '';
            this.customersQueryParams.doctorId = this.selectedSurgeon.doctorId;

            await this.fetchSearchResults({includeCustomers: true, includeSurgeons: false});

            if (this.customersProcessed.length === 1) {
                this.selectedCustomer = this.customersProcessed[0];
                this.goToModalState(ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED);
            } else {
                this.goToModalState(ModalStateNames.SURGEON_SELECTED);
            }
        },
        /**
         * Handle a customer selection by navigating to the appropriate modal
         * state
         *
         * @param {Object} customer
         */
        onCustomerSelected(customer) {
            this.selectedCustomer = customer;
            if (
                this.modalState.name === ModalStateNames.BASE ||
                this.modalState.name === ModalStateNames.VIEW_ALL_CUSTOMERS
            ) {
                this.goToModalState(ModalStateNames.CUSTOMER_SELECTED);
            } else if (this.modalState.name === ModalStateNames.SURGEON_SELECTED) {
                this.goToModalState(ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED);
            }
        },
        /**
         * Clear the selected surgeon
         */
        clearSelectedSurgeon() {
            this.selectedSurgeon = null;
        },
        /**
         * Clear the selected customer
         */
        clearSelectedCustomer() {
            this.selectedCustomer = null;
        },
        /**
         * Clear the surgeons
         */
        clearSurgeons() {
            this.surgeons = [];
            this.surgeonsProcessed = [];
        },
        /**
         * Clear the customers
         */
        clearCustomers() {
            this.customers = [];
            this.customersProcessed = [];
        },
        /**
         * Reset the query parameters for fetching the customers and doctors (surgeons)
         */
        resetQueryParams() {
            this.customersQueryParams = DefaultQueryParams().customers;
            this.surgeonsQueryParams = DefaultQueryParams().surgeons;
        },
        /**
         * Clear the default selection setting
         */
        clearDefaultSelectionSetting() {
            this.defaultSelectionSetting = DefaultSelectionOptions.UNSET_DEFAULT;
        },
        /**
         * Get the default selection setting. The default selection checkbox
         * will be checked if the user selects a customer and/or surgeon
         * that is already the current default.
         */
        getDefaultSelectionSetting() {
            if (this.modalState.name === ModalStateNames.CUSTOMER_SELECTED) {
                if (this.defaultCustomerId === this.selectedCustomer?.customerId) {
                    this.defaultSelectionSetting = DefaultSelectionOptions.SET_DEFAULT;
                } else {
                    this.defaultSelectionSetting = DefaultSelectionOptions.UNSET_DEFAULT;
                }
            } else if (this.modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED) {
                if (
                    this.defaultCustomerId === this.selectedCustomer?.customerId &&
                    this.defaultDoctorId === this.selectedSurgeon?.doctorId
                ) {
                    this.defaultSelectionSetting = DefaultSelectionOptions.SET_DEFAULT;
                } else {
                    this.defaultSelectionSetting = DefaultSelectionOptions.UNSET_DEFAULT;
                }
            }
        },
        /**
         * Handle the modal cancellation
         */
        onCancel() {
            this.$emit('cancel');
        },
        /**
         * Handle the back navigation
         */
        onBack() {
            this.fetchOnSearchTextChange = false;
            this.modalStateHistory.shift();
        },
        /** Handle final navigation to the dashboard. This includes setting the
         * active selection and the default selection (if any)
         */
        async onViewDashboard() {
            if (this.modalState.name === ModalStateNames.CUSTOMER_SELECTED) {
                if (this.defaultSelectionSetting === DefaultSelectionOptions.SET_DEFAULT) {
                    await this.setDefaultDoctorId(null);
                    await this.setDefaultCustomerId(this.selectedCustomer?.customerId);
                } else if (this.defaultSelectionSetting === DefaultSelectionOptions.UNSET_DEFAULT) {
                    await this.setDefaultCustomerId(null);
                }
            } else if (this.modalState.name === ModalStateNames.CUSTOMER_AND_SURGEON_SELECTED) {
                if (this.defaultSelectionSetting === DefaultSelectionOptions.SET_DEFAULT) {
                    await this.setDefaultCustomerId(this.selectedCustomer?.customerId);
                    await this.setDefaultDoctorId(this.selectedSurgeon?.doctorId);
                } else if (this.defaultSelectionSetting === DefaultSelectionOptions.UNSET_DEFAULT) {
                    await this.setDefaultCustomerId(null);
                    await this.setDefaultDoctorId(null);
                }
            }

            await this.setActiveCustomerId(this.selectedCustomer?.ocosCustomerNumber);
            await this.setActiveDoctorId(this.selectedSurgeon?.doctorId);

            this.$emit('customer-selected', {
                selectedCustomer: this.selectedCustomer,
                selectedSurgeon: this.selectedSurgeon,
            });

            this.$router.push({name: 'Dashboard'});
        },
    },
};
</script>
