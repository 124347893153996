export const OrderSubStatus = Object.freeze({
    /**RESERVED is not part of sub status but is set as the status when none substatus */
    RESERVED: 'Reserved',
    NONE: null,
    IN_CART: 'In Cart',
    ORDERED: 'Ordered',
    CANCELLED: 'Canceled',
    CONSUMED: 'Consumed',
    PREORDER: 'Pre-Order',
    PREORDER_RESERVATION: 'Pre-Order Reservation',
    IN_CART_PREORDER_RESERVATION: 'In Cart Pre-Order Reservation',
    PROCESSING: 'Processing',
    APPROVAL_PENDING: 'Approval Pending',
    CREDIT_REVIEW_PENDING: 'Credit Review Pending',
    DRAFT: 'Draft',
    REFERENCE: 'Reference',
});

export const PageName = Object.freeze({
    [OrderSubStatus.ORDERED]: 'OrderDetails',
    [OrderSubStatus.RESERVED]: 'ReserveDetails',
    [OrderSubStatus.IN_CART]: 'ReserveDetails',
    [OrderSubStatus.CONSUMED]: 'OrderDetails',
    [OrderSubStatus.PREORDER]: 'ReserveDetails',
    [OrderSubStatus.PREORDER_RESERVATION]: 'ReserveDetails',
    [OrderSubStatus.IN_CART_PREORDER_RESERVATION]: 'ReserveDetails',
    [OrderSubStatus.PROCESSING]: 'OrderDetails',
    [OrderSubStatus.APPROVAL_PENDING]: 'OrderDetails',
    [OrderSubStatus.CREDIT_REVIEW_PENDING]: 'OrderDetails',
    [OrderSubStatus.DRAFT]: 'OrderDetails',
    [OrderSubStatus.REFERENCE]: 'OrderDetails',
});
